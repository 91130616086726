.offcanvas.offcanvas-end{
  width:50%;
}
.offcanvas-title h5{
  display: flex;
  align-items: center;
}

.offcanvas-title h5 .edit-food{
  font-size: 14px;
  margin: 0 10px;
  cursor: pointer;
  color: #373535;
}

.offcanvas-title p{
  font-size: 14px;
  margin-bottom: 0;
}
.view-food-info.offcanvas-body{
  background: #f7f7f7;
}

.offcanvas .offcanvas-header{
  /* background: -webkit-linear-gradient(left,#DAE2F8 0,#f1c8c8 100%); */
  // background: -webkit-linear-gradient(left,#9febc2 0,#f7f7f7 100%);
}

.view-food-info * {
  font-family: 'Smitch Sans' !important;
}

.glycemic-add-section{
  display: flex;
  align-items: center;
  justify-content: right;
}

.glycemic-add-section input{
  max-width: 120px;
  margin-left: 10px;
  height: 30px;
}

.glycemic-add-section button{
  height: 30px;
}

.flex-nutrient{
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid lightgrey;
  margin: 10px 5px;
}

.hr-1{
  height: 10px;
  background: darkgray;
  border: none;
  margin: 5px 0;
}

.hr-2{
  height: 5px;
  background: darkgray;
  border: none;
  margin: 5px 0;
}


.nutrient-section{
  font-size:12px;
}

.bold{
  font-weight: 800;
}

.flex-nutrient.sub{
  padding-left: 10px;
}


.nutrient-section .font-1{
  font-size:16px !important;
}

.nutrient-section div.border-none{
  border-bottom:none !important;
}

.nutrient-top-card .item{
  background: #ffffff;
  border-radius: 10px;
  padding: 10px;
  box-shadow: 1px 1px 10px #bdbdbd;
}

.icon-1{
  color: green;
  margin-right: 5px;
}

.icon-2{
  color: yellow;
  margin-right: 5px;
}

.icon-3{
  color: red;
  margin-right: 5px;
}

.icon-4{
  color: lightgreen;
  margin-right: 5px;
}

.search-dropdown{
  display: flex;
  justify-content: space-between;
}

.Meal-Creation .search-dropdown div:first-child{
  max-width: 75%;
  white-space: break-spaces;
}

.search-dropdown small{
  font-size:12px;
}
.search-dropdown span:last-child{
  display: flex;
  align-items: center;
}

.cal-rounded{
  border: 2px solid red;
  width: 50px;
  height: 50px;
  text-align: center;
  border-radius: 50%;
  padding: 0px;
  font-size: 14px;
}

.Meal-Creation *,
.Manual-food-add *{
  font-family: 'Smitch Sans' !important;
}

.add-food-serving-header{
  display: flex;
  justify-content: space-between;
}

.inputGroup-Unit p{
  font-size: 12px;
}

.Manual-food-add label.MuiFormLabel-root{
  font-size: 13px;
  line-height: 1.7375em;
}

.Meal-Creation .food-item {
  box-shadow: 1px 1px 10px 1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.Meal-Creation .search-section svg{
  font-size:20px;
}

.Meal-Creation .search-section{
  display: contents;
}

.food-item{
  position: relative;
}

.food-item.deleted-meal{
  background-color: lightgray;
}

.food-item .remove-food{
  position: absolute;
  top: -10px;
  right: -10px;
  height: 20px;
  width: 21px;
  min-height: unset;
}

.food-item h4{
  font-size: 15px;
  line-height: 1.5;
  margin-bottom: 5px;
}
.food-item p{
  font-size: 14px;
  margin-bottom: 2px;
  font-weight: 800;
}

.FoodList-Section .dropdown-menu.show,
.Meal-Creation .dropdown-menu.show{
  max-height: 300px;
  overflow-y: scroll;
}

.dropdown-menu.show .food-name{
  white-space: normal;
}


.Meal-Creation .dropdown-menu.show {
  scrollbar-width: auto;
  scrollbar-color: #bdbbbb #ffffff;
}

/* Chrome, Edge, and Safari */
.Meal-Creation .dropdown-menu.show::-webkit-scrollbar {
  width: 10px;
  height: 15px;
}

.Meal-Creation .dropdown-menu.show::-webkit-scrollbar-track {
  background: #ffffff;
}

.Meal-Creation .dropdown-menu.show::-webkit-scrollbar-thumb {
  background-color: #bdbbbb;
  border-radius: 12px;
  border: 3px solid #ffffff;
}

.Meal-Creation .save-meal-btn {
  position: fixed;
  bottom: 15px;
  right: 25px;
  font-size: 25px;
}

.Meal-View .meal-food-item{
  padding-top: 10px;
  padding-bottom: 10px;
  border-bottom: 2px solid lightgray;
}
.Meal-View .meal-food-item h4{
  font-size: 16px;
  font-weight: 800;
}
.Meal-View .meal-food-item small{
  font-size: 13px;
  font-weight: 500;
}

.accordion{
  margin-top: 10px;
}
.accordion-button{
  padding: 10px 10px;
  font-size: 14px;
}
.accordion-button:focus{
  box-shadow: none;
}
/* .accordion-button::after{
  width: 1rem;
  height: 1rem;
} */
.accordion-body {
  padding: 10px
}

.accordion-body span{
  font-size: 12px;
}

.alert-no-suggestions{
  position: fixed;
  z-index: 99;
  padding: 10px;
  width: calc(50% - 2rem);
  text-align: center;
}


@media only screen and (max-width: 767px) {
  .offcanvas.offcanvas-end{
    width: 90%;
  }
}