.user-verification {
  .dt-table{
    border-radius: 10px !important;
    border: 1px solid lightgray;
  }
  .white-space-no-wrap{
    white-space: nowrap;
  }
  .dt-user-list {  
    
    .rdt_TableHead {
      .rdt_TableCol {
        background-color: #4b8bba;
        color: #fffefe;
        font-weight: 500 !important;
      }      
    }
    .rdt_TableHead {
      .rdt_TableCol {
        font-size: 14px;
        
        &:nth-child(2),
        &:nth-child(3)
        //&:nth-child(4) 
        {
          padding: 10px;
          text-align: start;
          justify-content: left;
        }

        &:nth-child(1),
        &:nth-child(4),
        &:nth-child(5),
        &:nth-child(6),
        &:nth-child(7),
        &:nth-child(8),
        &:nth-child(9){
          padding: 10px;
          text-align: center;
          justify-content: center;
        }
      }
    }

    .rdt_TableRow {
      .rdt_TableCell {
        &:nth-child(2),
        &:nth-child(3){
        // &:nth-child(4) {
          padding: 10px;
          text-align: start;
          justify-content: left;
        }
        &:nth-child(1),
        &:nth-child(4),
        &:nth-child(5),
        &:nth-child(6),
        &:nth-child(7),
        &:nth-child(8),
        &:nth-child(9){
          padding: 10px;
          text-align: center;
          justify-content: center;
        }
      }
    }

    .verification-source{
      .MuiChip-filled {
          border-radius: 5px;
          font-weight: 500;
          width: 85px;
          &.cst{
            background-color: #8b34ef33 !important;
            color: #8b1a72 !important;
          }
          &.typeform{            
            background-color: #ffe5c8;
            color: #ec8000;
          }
        }
    }
    .paid-status{
      .MuiChip-filled {
          border-radius: 5px;
          font-weight: 500;
          &.Paid{
            background-color: #cdfde0 !important;
            color: #008939 !important;
          }
          &.Not-Paid{            
            background-color: #ffdada !important;
          color: red !important;
          }
        }
    }
    .payment-status{
      .MuiChip-filled {
          border-radius: 5px;
          font-weight: 500;
          &.Success{
            background-color: #cdfde0 !important;
            color: #008939 !important;
          }
          &.Initiated{            
            background-color: #ffe5c8;
            color: #ec8000;
          }
          &.Not-Initiated{            
            background-color: #e0daff !important;
            color: rgb(95, 1, 189) !important;
          }
        }
    }
    .elegible-status{
      .MuiChip-filled {
        border-radius: 5px;
        font-weight: 500;
        &.Not-Yet{
          background-color: #ffe5c8 !important;
          color: #ec8000;
        }
        &.Eligible{
          background-color: #cdfde0 !important;
          color: #008939 !important;
        }
        &.Not-Eligible{
          background-color: #ffdada !important;
          color: red !important;
        }
      }
    }

  }
  .verified{
    color: green;
  }
  .not-verified{
    color: #ff5500;
  }
  // .paid-label {
  //   font-size: 12px;
  //   text-transform: capitalize;
  //   .not-paid{
  //     color: red;
  //     border:2px solid red;
  //     border-radius: 5px;
  //     padding: 5px;
  //     white-space: nowrap;
  //   }
  //   .paid{
  //     color: green;
  //     border:2px solid green;
  //     border-radius: 5px;
  //     padding: 5px;
  //     white-space: nowrap;
  //   }
    
  // }
}
.user-information { 

  .offcanvas-header {
    background: linear-gradient(45deg, #2f7aaf, #dde7f3);
    color: #fffefe;
    &.footer {
      background: linear-gradient(45deg, #dde7f3, #dde7f3);
      display: flex;
      justify-content: space-between;

      .payment-done-chip{
        width: 100%;
        border-radius: 5px;
      }
    }

  }

  .status-section{
    display: flex;
    justify-content: space-between;

    &.verification-source{
      .MuiChip-filled {
          border-radius: 5px;
          font-weight: 500;
          width: 85px;
          &.cst{
            background-color: #8b34ef33 !important;
            color: #8b1a72 !important;
          }
          &.typeform{            
            background-color: #ffe5c8;
            color: #ec8000;
          }
        }
    }
    &.verified-status{
      .MuiChip-filled {
          border-radius: 5px;
          font-weight: 500;
          width: 85px;
          &.Verified{
            background-color: #cdfde0 !important;
            color: #008939 !important;
          }
          &.Not-Verified{            
            background-color: #ffdada !important;
          color: red !important;
          }
        }
    }
    &.paid-status{
      .MuiChip-filled {
          border-radius: 5px;
          font-weight: 500;
          &.Paid{
            background-color: #cdfde0 !important;
            color: #008939 !important;
          }
          &.Not-Paid{            
            background-color: #ffdada !important;
          color: red !important;
          }
        }
    }
   
    &.elegible-status{
      .MuiChip-filled {
        border-radius: 5px;
        font-weight: 500;
        &.Not-Yet{
          background-color: #ffe5c8 !important;
          color: #ec8000;
        }
        &.Eligible{
          background-color: #cdfde0 !important;
          color: #008939 !important;
        }
        &.Not-Eligible{
          background-color: #ffdada !important;
          color: red !important;
        }
      }
    }
    
    .MuiChip-root{
      min-width: 100px;
      border-radius: 5px;
     
    }
  }

  .input-section {
    .input-field {
      margin-bottom: 1rem;
    }

    .input-header-chip{
      width: 100%;
      margin-bottom: 1rem;
      border-radius: 5px;
    }
    
  }
  .info-section{

   

    .address-section-heading{
      font-size: 14px;   
      margin-bottom: 5px; 
      font-weight: 700;
    }
    .address-section-details{
      font-size: 14px;  
      margin-left: 20px; 
    }

    .card{
      background-color: #fff;
      .card-body{
        .info-item{
          &:last-child{
            margin-bottom: 0px;
          }
        }
      }
      margin-bottom: 10px;
    }
    .header-chip{
      width: 100%;
      margin-bottom: 0px;
      border-radius: 5px;
      svg{
        color: #fffefe;
      }
    }
    svg{
      color: #4085b6;
    }
    p{
      &.user-name{
        font-size: 20px;
        text-align: center;
        font-weight: 600;
      }
      &.user-id{
        font-size: 18px;
        text-align: center;
        font-weight: 600;
      }
      &.user-contact{
        text-align: center;
        font-weight: 600;
        span{
          text-align: left;
        }
      }
    }
    p{
      &.info-item{
        display: flex;
        justify-content: space-between;
        font-size: 14px;
      }
      small{
        // float: inline-end;
        svg{
          margin-right: 5px;
        }
        &.verified{
          svg{
            color: green;
          }
          color: green;
        }
        &.not-verified{
          svg{
            color: red;
          }
          color: red;
        }
      }
    }
    .div-block{
      position: relative;
    }
    
    .verified-status{
      img{
        width: 100px;
        position: absolute;
        right: 20%;
        bottom: 10%;
      }
    }
    .paid-status{
      img{
        width: 100px;
        position: absolute;
        right: 0%;
        bottom: 5%;
      }     

      .paid{
        color: green;       
        border:2px solid green;
        font-size: 14px;
        text-transform: capitalize;
        min-width: 80px;
        border-radius: 5px;
        padding: 5px;
        white-space: nowrap;
      }
    }
  }

}

.filter-container {
  max-width: 100%; 
  background-color: #fff;   
  border-radius: 10px;
  
  .MuiToggleButtonGroup-root{
    button{
      font-weight: 600;
    }
  }

  .content {    
    overflow: hidden;
    max-height: 0;
    padding: 0 10px;
    margin-bottom: 0px;
    transition: max-height 0.5s ease-out, padding 0.4s ease-out, margin 0.4s ease-out;
    display: flex;
    justify-content: space-between;
  }

  .content.expanded {    
    max-height: 250px;
    padding: 10px;
    margin-bottom: 10px;
    transition: max-height 0.5s ease-in, padding 0.4s ease-in, margin 0.4s ease-in;
  }


}

.country-code-item{
  display: flex !important;
  justify-content: space-between !important;
}

.top-action-button-group{
  display: flex;
  justify-content: flex-end;
  align-items: center;
  button{
    font-size: 14px;
  }
}


@media only screen and (max-width: 767px) {  
  .offcanvas{
    &.user-information{
      width: 90% !important;
    }
  }

    .user-verification {
      .dt-table {  
        .rdt_TableRow .rdt_TableCell,
        .rdt_TableHead .rdt_TableCol {
          width: max-content;
        }

        // .rdt_TableRow .rdt_TableCell,
        // .rdt_TableHead .rdt_TableCol{
         
        //   &:nth-child(1){
        //     min-width: 7%;
        //   }
        //   &:nth-child(2){
        //     min-width: 15%;
        //   }
        //   &:nth-child(3){
        //     min-width: 35%;
        //   }
        //   &:nth-child(7){
        //     min-width: 12%;
        //   }
          
        //   &:not(:nth-child(1)):not(:nth-child(2)):not(:nth-child(3)):not(:nth-child(7)) {
        //     min-width: 10%;
        //   }
        // }
        .rdt_TableRow:nth-child(even) .rdt_TableCell{
          background-color: #fff;
        }
        .rdt_TableRow:nth-child(odd) .rdt_TableCell{
          background-color: #fafafa;
        }
      }
    }


  .top-action-button-group{
    margin-bottom: 5px;
    display: flex;
    justify-content: space-between;
    padding: 0;

    button{
      span{
        display: none;
      }
    }
    
  }
  .filter-container {
    .content {
      justify-content: space-between;
      flex-direction: column;
      display: flex;
      align-items: center;

      .clear-filter-button{
        width: 100%;
      }

      .MuiToggleButtonGroup-root{
        margin-bottom: 10px;
        width: 100%;
        button{
          white-space: nowrap;
          flex:1;
          // margin: 0 5px;
        }
      }
    }
  }
}