.main-component{
  padding: 1.5rem 1rem;
}

.pl-0{
  padding-left: 0px;
}

.pr-0{
  padding-right: 0px;
}

.ml-0{
  margin-left: 0px;
}

.mr-0{
  margin-right: 0px;
}

.mr-1{
  margin-right: 5px;
}
.pr-1{
  padding-right: 5px;
}
.ml-1{
  margin-left: 5px;
}
.pl-1{
  padding-left: 5px;
}

.dashboard-banner{
  width: 100%;
  border-radius: 10px;
  padding: 10px 0;
}

.notification-icon {
  filter: drop-shadow(1px 1px 5px #fff);

  svg {
    color: #272727 !important;    
    height: 22px !important;
  }
}

@media only screen and (min-width: 767px) {
  .main-component {
    max-height: calc(100vh - 64px);
    overflow-y: auto;
    transition: max-width 0.3s ease;     
  }
  .sidebar-open{
    .main-component {
      max-width: calc(100vw - 250px);
    }
  }
  .sidebar-close{
    .main-component {
      max-width: calc(100vw - 80px);
    }
  }
  
}


.main-component {
  scrollbar-width: thin;
  scrollbar-color: #2f7aaf #ffffff;
}

.main-component::-webkit-scrollbar {
  width: 10px;
}

.main-component::-webkit-scrollbar-track {
  background: #ffffff;
}

.main-component::-webkit-scrollbar-thumb {
  background-color: #2f7aaf;
  border-radius: 10px;
  border: 2px solid #ffffff;
}

.main-component::-webkit-scrollbar-thumb:hover {
  background-color: #6b5ec9;
}

