.meal-list,
.food-list{
  &.search-section{
      display: flex;
      align-items: flex-start;
      justify-content: flex-end;
      align-items: center;
    padding-right: 0;
    button{
      margin-left: 5px;
      white-space: nowrap;
    }
  }
}
.dt-section{
  border-radius: 10px;
  .rdt_Table{
    .rdt_TableHead{
      .rdt_TableCol {
        font-weight: 500 !important;
        background-color: #4b8bba;
        color: white;
        padding: 10px 0px;
        font-size: 14px;
        justify-content: flex-start;

        &:first-child,
        &:last-child{
          justify-content: center;
          text-align: center;
        }
      }
      .rdt_TableHeadRow{
        .tHead {
          display: flex;
          width: 100%;
          padding: 5px 0px;
          align-items: center;        
        }
      }
      .rdt_TableCol{
        justify-content: center;
        text-align: center;
      }
    }
    .rdt_TableBody{}
    .rdt_TableRow {
      .rdt_TableCell{
        font-size: 14px;
        padding: 10px 0px;
        padding-right:10px;

        &:first-child,
        &:last-child{
          justify-content: center;
          text-align: center;
        }
      }
    }
  }

  &.food-dt{
    .rdt_Table{
      .rdt_TableHead{
        .rdt_TableCol {
          .tHead{
            justify-content: center;
            text-align: center;
          }            
          &:nth-child(2) .tHead{
            justify-content: flex-start !important;
            text-align: left !important;
          }
        }
      }
      .rdt_TableRow {
        .rdt_TableCell{
          justify-content: center;
          text-align: center;
          &:nth-child(2){
            justify-content: flex-start !important;
            text-align: left !important;
          }
          div{
            white-space: normal;
            overflow: unset;
            text-overflow: unset;
            line-height: 1.5;
          }
        }
  
      }
    }

  }
  &.meal-dt{
    .rdt_Table{
      .rdt_TableHead{
        .rdt_TableCol {
          &:nth-child(2),
          &:nth-child(3){
            justify-content: flex-start !important;
            text-align: left !important;
          }
        }
      }
      .rdt_TableRow {
        .rdt_TableCell{
          justify-content: center;
          text-align: center;
          &:nth-child(3),
          &:nth-child(2){
            justify-content: flex-start !important;
            text-align: left !important;
          }
          div{
            white-space: normal;
            overflow: unset;
            text-overflow: unset;
            line-height: 1.5;
          }
        }
  
      }
    }

  }
}

/* 
.rdt_Table .rdt_TableBody div>div>div {
  width: 100%;
}

.rdt_TableCol>div>div {
  overflow: unset;
  white-space: normal;
  text-overflow: ellipsis;
  padding: 5px 0px;
}

.rdt_TableCell>div {
  white-space: normal !important;
}


.rdt_TableRow .rdt_TableCell>div {
  text-align: left;
}
.rdt_TableRow .rdt_TableCell:first-child>div {
  text-align: center;
}
.rdt_TableRow .rdt_TableCell:last-child {
  justify-content: center;
}
 */
/*  */
.dropdown-menu.show {
  width: 100%;
}



@media only screen and (max-width: 767px) {
  .search-section {
    justify-content: space-between;
  }
  .food-list.search-section{
    margin-bottom: 10px;
  }
}


.id .ASC,
.id .DESC,
.name .ASC,
.name .DESC,
.glycemic_index .ASC,
.glycemic_index .DESC,
.glycemic_index_multiplier .ASC,
.glycemic_index_multiplier .DESC,
.glycemic_index_ai .ASC,
.glycemic_index_ai .DESC {
  display: none;
}

.id.DESC .id .DESC,
.id.ASC .id .ASC,
.name.DESC .name .DESC,
.name.ASC .name .ASC,
.glycemic_index.DESC .glycemic_index .DESC,
.glycemic_index.ASC .glycemic_index .ASC,
.glycemic_index_multiplier.DESC .glycemic_index_multiplier .DESC,
.glycemic_index_multiplier.ASC .glycemic_index_multiplier .ASC,
.glycemic_index_ai.DESC .glycemic_index_ai .DESC,
.glycemic_index_ai.ASC .glycemic_index_ai .ASC {
  display: block;
}

/*  */
.food-search-section {
  border: 1px solid #c4c4c4;
  box-shadow: none;
  height: 40px;
}

.food-search-section svg {
  font-size: 20px;
}
