.whatsapp-query-section {
  height: calc(100vh - 7rem);

  .user-message-list {
    height: 100%;
    .MuiGrid-container {
      min-height: 100%;
    }
    .left-side,
    .card {
      min-height: 100%;
    }
    .right-side {
      height: 100%;
    }
  }

  .user-message-list {
    .left-side {
      .card {
        border-radius: 0px;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
        .card-header {
          height: 60px;
          padding: 10px;
          & > div {
            display: flex;
            align-items: center;

            .user-avatar {
              border: 1px solid #cacaca;
              padding: 2px;
            }

            h4 {
              font-size: 20px;
              text-align: start;
              margin-bottom: 0;
              small {
                font-size: 13px;
                color: #8e8696;
              }
            }
          }

          // align-items: center;
          // display: flex;
          // justify-content: flex-start;
          // h4{
          //   font-size: 20px;
          //   margin-bottom: 0;
          // }
        }
        .card-body {
          padding: 0;
          background: #f2f2f2;

          .message-short {
            max-height: calc(100vh - 230px);
            overflow-y: scroll;
          }

          .whatsapp-search-section {
            border-bottom: 1px solid #cacaca;
            background-color: #fff;
            padding: 5px 10px;
            display:flex;
            flex-direction:row;
            align-items: center;
            
            .MuiOutlinedInput-root {
              border-radius: 40px;
            }
            .input {
              border-radius: 10%;
            }
          }
        }

        .message-item {
          cursor: pointer;
          padding: 10px;
          border-bottom: 1px solid #cacaca;
          background: #fff;
          &.active {
            background-color: #e9e9e9;
          }
          .MuiGrid-container {
            margin-top: 0;
            margin-left: 0;
            width: 100%;
          }

          .profile-left {
            padding-left: 0;
            align-items: center;
            display: flex;
            justify-content: center;

            .user-avatar {
            }
          }

          .content {
            text-align: start;
            .phone {
              font-size: 15px;
              margin-bottom: 5px;
              small {
                float: right;
                font-size: 12px;
              }
            }
            .message {
              font-size: 13px;
              color: #868686;
              margin-bottom: 5px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              max-width: 90%;
            }
          }

          .status {
            margin-left: auto;
            font-weight: 500;
            background-color: #ffefdd;
            color: #ec8000;
            border: 2px solid #ffefdd;
            font-size: 12px;
            border-radius: 5px;
            padding: 0px 10px;
            font-weight: 500;
            // min-width: 80px;
            margin-top: 5px;
            text-align: center;
            width: fit-content;
          }
        }
        .todo-status {
          cursor: pointer;
          padding: 10px;
          border-bottom: 1px solid #cacaca;
          background: #d2f8c1;
          &.active {
            background-color: #e9e9e9;
          }
          .MuiGrid-container {
            margin-top: 0;
            margin-left: 0;
            width: 100%;
          }

          .profile-left {
            padding-left: 0;
            align-items: center;
            display: flex;
            justify-content: center;

            .user-avatar {
            }
          }

          .content {
            text-align: start;
            .phone {
              font-size: 15px;
              margin-bottom: 5px;
              small {
                float: right;
                font-size: 12px;
              }
            }
            .message {
              font-size: 13px;
              color: #868686;
              margin-bottom: 5px;
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              max-width: 90%;
            }
          }

          .status {
            margin-left: auto;
            background-color: #ffefdd;
            color: #ec8000;
            border: 2px solid #ffefdd;
            font-size: 12px;
            border-radius: 5px;
            padding: 0px 10px;
            font-weight: 500;
            // min-width: 80px;
            text-align: center;
            width: fit-content;
          }
        }
      }
    }
  }
  .user-full-message-view {
    &.card {
      border-radius: 0px;
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;

      .card-header {
        height: 60px;
        .contact-number {
          display: flex;
          h4 {
            font-size: 16px;
            text-align: start;
            margin-bottom: 0;
            small {
              font-size: 13px;
              color: #8e8696;
            }
          }
        }
      }
      .text-start {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
      }


      .card-footer {
        height: 100px;
        .whatsapp-send-message-section {
          display: flex;
          align-items: center;
          .MuiTextField-root {
            margin-right: 10px;
            .MuiOutlinedInput-root {
              border-radius: 10px;
            }
          }
          .MuiIconButton-root {
            background-color: #e7e5e5;
            height: 40px;
            width: 40px;
            svg {
              font-size: 18px;
            }
          }
        }
      }

      .card-body {
        background: #f2f2f2;
        background-image: url("../../assets/img/bg-whatsapp.png");
        background-position: center;
        background-size: contain;
        max-height: calc(100vh - 262px);
        height: calc(100vh - 262px);
        overflow-y: scroll;
        padding-bottom: 0;
        .messages {
          .no-more-messages {
            padding: 3px;
            width: 100px;
            text-align: center;
            align-self: center;
            margin: auto;
            border-radius: 2px;
            font-size: 10px;
            color: #757575; /* Similar to WhatsApp's muted color */
            background-color: #cfdcf5; /* Similar to WhatsApp's background color */
          }
          .date-difference {
            padding: 3px;
            width: 100px;
            text-align: center;
            align-self: center;
            margin: auto;
            border-radius: 2px;
            margin-top: 2px;

            font-size: 10px;
            color: #757575; /* Similar to WhatsApp's muted color */
            background-color: #cfdcf5;
          }
          .msg-container {
            display: flex;

            &.user-msg {
              justify-content: flex-start;
              .line-break{
                white-space: pre-line;
              }
              .msg {
                border-top-left-radius: 0px;
                background-color: white;
                border: 1px solid #cacaca;
                text-align: start;
                padding-right: 40px;
                margin-top: 2px;
                padding-bottom:20px;
                max-width:80%;
                .msg-img{
                width:300px;
                height:auto;
                padding-left:20px;
                padding-top:20px;
                }
                &:after {
                  top: -1px;
                  border-width: 6px;
                  margin: 0;
                  border-left-color: transparent;
                  border-right-color: #cacaca;
                  left: -13px;
                  right: auto;
                  bottom: auto;
                  border-top-color: #cacaca;
                }
                .msg-timestamp {
                  position: absolute;
                  bottom: 5px;
                  right: 5px; 
                  font-size: 12px;
                  color: #757575; 
                }
              }
             
            }

            &.team-msg {
              justify-content: flex-end;
              .line-break{
                white-space: pre-line;
              }
              .msg {
                text-align: start;
                border-top-right-radius: 0px;
                background-color: #dff2ca;
                border: 1px solid #cfe9b2;
                padding-right:40px;
                padding-bottom:25px;
                max-width: 80%;
                .msg-img{
                  width:300px;
                  height:auto;
                  padding-left:20px;
                  padding-top:20px;
                  }
                &:after {
                  top: -1px;
                  border-width: 6px;
                  margin: 0;
                  border-right-color: transparent;
                  border-left-color: #dff2ca;
                  right: -13px;
                  left: auto;
                  bottom: auto;
                  border-top-color: #dff2ca;
                }
                .msg-timestamp {
                  position: absolute;
                  bottom: 5px;
                  left: auto; /* Adjust the position to the right */
                  right: 5px; /* Position the timestamp on the right side */
                  font-size: 12px;
                  color: #757575;
                  white-space: nowrap;
                }
              }
            }
          }
          .msg {
            font-size: 13px;
            padding: 5px 10px;
            width: fit-content;
            border-radius: 10px;
            position: relative;
            min-width: 85px;

            &:after {
              content: "";
              display: block;
              border-style: solid;
              border-color: #cacaca transparent transparent;
              position: absolute;
            }
          }
        }
      }
    }
  }
  .initial-msg-screen{
    height: 80%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    img{
      max-width: 180px;
    }
  }
  
}

.lightbox {
	display: none;
	position: fixed;
	z-index: 9999;
	width: 100%;
	height: 100%;
	text-align: center;
	top: 0;
	left: 0;
	background: rgba(0, 0, 0, 0.8);

	img {
		max-width: 80%;
		max-height: 80%;
	}

	&.show {
    display: flex;
    align-items: center;
    justify-content: center;
	}
  .close{
    position: absolute;
    top: 15px;
    right: 25px;
    font-size: 24px;
    color: white;
    cursor: pointer;
  }
}



@media only screen and (min-width: 767px) {
  .back-to-list {
    display: none !important;
  }
}
@media only screen and (max-width: 767px) {  

  .whatsapp-query-section {
      .user-message-list {
        .left-side {
          .card {
            border-top-right-radius: 10px;
            border-bottom-right-radius: 10px;
            border-bottom-left-radius: 10px;
            .card-body{
              border-bottom-right-radius: 10px;
                border-bottom-left-radius: 10px;
              .message-short{
                border-bottom-right-radius: 10px;
                border-bottom-left-radius: 10px;
              }
            }
          }
        }
        .MuiAvatar-img{
          height: auto;
        }
      }  
      .user-full-message-view {
        &.card {
          border-top-left-radius: 10px;
          border-bottom-left-radius: 10px;

          .progress-text{
            // visibility: hidden;
            display: none;
          }
          .card-body {
              min-height: calc(100vh - 224px);
          
              .messages {
                  .msg-container {
                    &.team-msg,
                    &.user-msg {
                      .msg {
                        .msg-img {
                          width: 100%;
                          height: auto;
                          padding-left: 10px;
                          padding-top: 10px;
                        }
                      }
                    }
                  }
                }
              }
           .card-footer{
            border-bottom-left-radius: 10px;
           }
        }
  
      }
      .responsive-open{
        display: block;
      }
      .responsive-close{
        display: none;
      }
    }
}

