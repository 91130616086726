.offcanvas * {
  font-family: 'Smitch Sans' !important;
}

.offcanvas {
  width: 45% !important;
  background: #faffff;
}

.offcanvas-header {
  background: linear-gradient(45deg, #2f7aaf, #dde7f3);
  color: #fffefe;
  padding: 10px;
}


/* vars */
:root {
  --back: #eeeeee;
  --orange: #ffa200;
  --blue: #0082d2;
  --green: #1c6d01;
  --gray: #777777;
  --size: 100%;
}

.tracking-wrapper {
  margin: 30px;
  padding: 0;
}

.tracking * {
  padding: 0;
  margin: 0;
}

.tracking {
  width: var(--size);
  max-width: 100%;
  position: relative;
}

.tracking .empty-bar {
  background: #ddd;
  position: absolute;
  width: 90%;
  height: 20%;
  top: 40%;
  margin-left: 5%;
}

.tracking .color-bar {
  background: var(--blue);
  position: absolute;
  height: 20%;
  top: 40%;
  margin-left: 5%;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
}

.tracking ul {
  display: flex;
  justify-content: space-between;
  list-style: none;
}

.tracking ul>li {
  background: #ddd;
  text-align: center;
  border-radius: 50%;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  z-index: 1;
  background-size: 70%;
  background-repeat: no-repeat;
  background-position: center center;
  transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -ms-transition: all 0.5s;
  -o-transition: all 0.5s;
  display: inline-block;
  position: relative;
  width: 30px;
  height: 30px;
}

.tracking ul>li .el {
  position: relative;
}

.tracking ul>li .el svg {
  opacity: 0;
  color: white;
}

.tracking ul>li .txt {
  color: #999;
  position: absolute;
  top: 120%;
  left: -75%;
  text-align: center;
  width: 250%;
  font-size: .75rem;
}

.tracking .progress-0 .color-bar {
  width: 0%;
}

.tracking .progress-1 .color-bar {
  width: 30%;
}

.tracking .progress-2 .color-bar {
  width: 60%;
}

.tracking .progress-3 .color-bar {
  width: 90%;
}

.tracking ul>li:is(.bullet-1, .bullet-2, .bullet-3, .bullet-4) {
  display: flex;
  align-items: center;
  justify-content: center;
}

.tracking .progress-0>ul>li.bullet-1,
.tracking .progress-1>ul>li.bullet-1,
.tracking .progress-2>ul>li.bullet-1,
.tracking .progress-3>ul>li.bullet-1 {
  background-color: var(--orange);
}

.tracking .progress-1>ul>li.bullet-2,
.tracking .progress-2>ul>li.bullet-2,
.tracking .progress-3>ul>li.bullet-2 {
  background-color: var(--blue);
}

.tracking .progress-2>ul>li.bullet-3,
.tracking .progress-3>ul>li.bullet-3 {
  background-color: var(--blue);
}

.tracking .progress-3>ul>li.bullet-3 {
  background-color: var(--blue);
}

.tracking .progress-3>ul>li.bullet-4 {
  background-color: var(--green);
}

.tracking .progress-0>ul>li.bullet-1 .el svg,
.tracking .progress-1>ul>li.bullet-1 .el svg,
.tracking .progress-2>ul>li.bullet-1 .el svg,
.tracking .progress-3>ul>li.bullet-1 .el svg {
  opacity: 1;
}

.tracking .progress-1>ul>li.bullet-2 .el svg,
.tracking .progress-2>ul>li.bullet-2 .el svg,
.tracking .progress-3>ul>li.bullet-2 .el svg {
  opacity: 1;
}

.tracking .progress-2>ul>li.bullet-3 .el svg,
.tracking .progress-3>ul>li.bullet-3 .el svg {
  opacity: 1;
}

.tracking .progress-3>ul>li.bullet-4 .el svg {
  opacity: 1;
}

.tracking .progress-0>ul>li.bullet-1 .txt,
.tracking .progress-1>ul>li.bullet-1 .txt,
.tracking .progress-2>ul>li.bullet-1 .txt,
.tracking .progress-3>ul>li.bullet-1 .txt {
  color: var(--orange);
}

.tracking .progress-1>ul>li.bullet-2 .txt,
.tracking .progress-2>ul>li.bullet-2 .txt,
.tracking .progress-3>ul>li.bullet-2 .txt {
  color: var(--blue);
}

.tracking .progress-2>ul>li.bullet-3 .txt,
.tracking .progress-3>ul>li.bullet-3 .txt {
  color: var(--blue);
}

.tracking .progress-3>ul>li.bullet-3 .txt {
  color: var(--blue);
}

.tracking .progress-3>ul>li.bullet-4 .txt {
  color: var(--green);
}

/*  */
.user-info {
  margin-left: 0px;

  .premium-modal{
    padding: 0.5rem;
    box-shadow: 1px 1px 10px #c7c7c7;
    border: none;
  }

  .info{
    display: flex;
    justify-content: flex-start;
    .user-avatar{
      height: 45px;
      width: 45px;
      img{
        height: auto;
      }
    }
    .icon-button{
      background-color: #f3f3f9;
      height: 45px;
      width: 45px;
      margin-right: 10px;
    }
    .content-section{
      p{
        margin-bottom: 2px;
        &.title{
          font-size: 13px;
          color: #878a99;
        }
        &.value{
          font-size: 14px;
          color: #28084b;
          font-weight: 500;
        }
      }
      .ring-section{ 
        display: flex;
        justify-content: space-between;
        align-items: center;
      .ring-color {
        height: 15px;
        width: 15px;
        display: block;
        border-radius: 10%;
      }
    }
    }
  }
}


.user-info p {
  margin-bottom: 2px;
  font-size: 13px;
}

.user-info h5 {
  margin-bottom: 2px;
  font-size: 20px;
  font-weight: 800;
}

.order-info p {
  margin-bottom: 2px;
  font-size: 13px;
}

.order-details-cards h6 {
  font-size: 13px;
  margin-bottom: 10px;
  font-weight: 800;
}

.order-details-cards p {
  font-size: 13px;
  margin-bottom: 5px;
}

.order-edit-info .MuiSelect-select {
  padding: 7px 14px;
}

.order-edit-info input,
.user-edit-info textarea,
.user-edit-info input {
  font-size: 14px !important;
}

/* create */
.dropdown-menu.show {
  width: 100%;
  padding: 0px;
}

.dropdown-item {
  padding: 10px 10px;
  border-bottom: 1px solid darkgray;
}


.dropdown-item:focus,
.dropdown-item:hover {
  color: #303031;
  background-color: #ededed;
}


.create-Kit-section{
  .search-user-dropdown{
    h4 {
      font-size: 16px;
      margin-bottom: 5px;
      font-weight: 800;
    }
    
    p {
      font-size: 13px;
      margin-bottom: 5px;
      display: flex;
      justify-content: space-between;
    }
  }
  .user-edit-info{
    background: linear-gradient(45deg, #c9dfff, #fffbca);
  }
  .create-order-info {
    .user-details{
      h5 {
        font-size: 16px;
        font-weight: 800;
        display: flex;
        justify-content: space-between;
        
      }
      
    }
  }
}

// .kit-modal-user-info{

//     padding: 0.5rem;
//     box-shadow: 1px 1px 10px #c7c7c7;
//     border: none;

//   .info{
//     display: flex;
//     justify-content: flex-start;
//     .user-avatar{
//       height: 45px;
//       width: 45px;
//       img{
//         height: auto;
//       }
//     }
//     .icon-button{
//       background-color: #f3f3f9;
//       height: 45px;
//       width: 45px;
//       margin-right: 10px;
//     }
//     .content-section{
//       p{
//         margin-bottom: 2px;
//         &.title{
//           font-size: 13px;
//           color: #878a99;
//         }
//         &.value{
//           font-size: 14px;
//           color: #28084b;
//           font-weight: 500;
//         }
//       }
//       .ring-section{ 
//         display: flex;
//         justify-content: space-between;
//         align-items: center;
//       .ring-color {
//         height: 15px;
//         width: 15px;
//         display: block;
//         border-radius: 10%;
//       }
//     }
//     }
//   }

//   .card-header{
//     background-color: #fff;
//   }

//   .kit-shipment{
//     margin-top: 10px;
//     .card{
//       border: none;
//       box-shadow: 1px 1px 10px #c7c7c7;
//     }
//     .card-header{
//       background-color: #ffe5c8;
//       color: #ec8000;
//     }
//   }
//   .order-shipment{
//     .card{
//       border: none;
//       box-shadow: 1px 1px 10px #c7c7c7;
//     }
//     .card-header{
//       background-color: #ffe5c8;
//       color: #ec8000;
//     }
//   }
// }





.create-order-info .user-details p {
  font-size: 13px;
  font-weight: 500;
  margin-bottom: 5px;
}

.create-order-info .input-section {
  margin: 20px 0;
}

.delete-order-section .MuiButton-contained{
  margin-top: 15px;
}

@media only screen and (max-width: 767px) {
  .offcanvas {
    width: 90% !important;
  }
}

.chip-border{
  border-radius: 5px !important;
  text-transform: capitalize;
}