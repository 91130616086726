.html-editor{
  .css-box,
  .html-box{
    .editor-input{
      width: 100%;
      margin-bottom:1rem;
    }
    width: 100%;
    textarea{
      width: 100%;
      min-height: 35vh;
    }
  }
  .output{
    border:1px solid #bbbbbb;
    border-radius: 4px;
    padding: 16.5px 14px;
    iframe{
      width: 100%;      
      height: calc(77vh);
    }
  }
}