.roles-section {
  .dt-table {
    border-radius: 10px !important;
    border: 1px solid lightgray;

    .status-button{
      text-transform: capitalize;
      cursor: auto;
      &.active{
        background-color: #e1ffed !important;
        color: #33b469 !important;
        border-color: #e1ffed;
      }
      &.inactive{
        background-color: #ffdada7d !important;
        color: red !important;
        border-color: #ffdada7d;
      }
    }

    .edit-button{
      text-transform: capitalize;
    }
    .rdt_TableHead {
      .rdt_TableCol {
        background-color: #4b8bba;
        color: #fffefe;
        font-weight: 600 !important;
        font-size: 14px;
        
        &:nth-child(2){
          padding: 10px;
          text-align: start;
          justify-content: left;
        }
      }
    }
    .rdt_TableRow 
    {
      .rdt_TableCell{
        font-size: 14px;
        &:nth-child(2){
          padding: 10px;
          text-align: start;
          justify-content: left;
        }
      }
    }
  }
}

.permission-section{
  &.offcanvas{
    width: 50% !important;
  }

}