.premium-user-section{
  .white-space-no-wrap{
    white-space: nowrap;
  }
  .all-select-toogle{
    color: #9c27b0;
    background-color: rgba(156, 39, 176, 0.08);
  }
  .dt-user-list {   

    .rdt_TableHead {
      .rdt_TableCol {
        background-color: #4b8bba;
        color: #fffefe;
        font-weight: 500 !important;
      }      
    }
    .rdt_TableHead {
      .rdt_TableCol {
        &:nth-child(5),
        &:nth-child(2)
        {
          padding: 10px;
          text-align: start;
          justify-content: left;
        }
        &:nth-child(1),
        &:nth-child(3),
        &:nth-child(6),
        &:nth-child(10),
        &:nth-child(4){
          padding: 10px;
          text-align: center;
          justify-content: center;
        }
      }
    }

    .rdt_TableBody{
      .rdt_TableRow{
        
        // border-bottom: 1px solid rgba(0, 0, 0, 0.12); 
        &:nth-child(odd){
          background-color: #fafafa;
          .rdt_TableCell{
            background-color: #fafafa;
            border-bottom: 1px solid rgba(0, 0, 0, 0.12);
          }
        }
        &:nth-child(even){
          background-color: #fff;
          .rdt_TableCell{
            background-color: #fff;
            border-bottom: 1px solid rgba(0, 0, 0, 0.12);
          }
        }
      }
    }

    .rdt_TableRow {
      .rdt_TableCell {
        &:nth-child(5),
        &:nth-child(2) {
          padding: 10px;
          text-align: start;
          justify-content: left;
        }
        &:nth-child(1),
        &:nth-child(3),
        &:nth-child(4),
        &:nth-child(10),
        &:nth-child(6){
          padding: 10px;
          text-align: center;
          justify-content: center;
        }
        

        .sync-button{
          background-color: #ffe5c8;
          color: #ec8000;
          border-radius: 5px;
          cursor: pointer;
        }

        .sync-reg-button{
          background-color: #e3c8ff;
          color: #4700ec;
          border-radius: 5px;
          cursor: pointer;
        }
    
      }
    }

    .verification-source{
      .MuiChip-filled {
          border-radius: 5px;
          font-weight: 500;
          &.cst{
            background-color: #cdfde0 !important;
            color: #33b469 !important;
          }
          &.typeform{            
            background-color: #ffe5c8;
            color: #ec8000;
          }
        }
    }
    .paid-status{
      .MuiChip-filled {
          border-radius: 5px;
          font-weight: 500;
          &.Paid{
            background-color: #cdfde0 !important;
            color: #33b469 !important;
          }
          &.Not-Paid{            
            background-color: #ffdada !important;
            color: red !important;
          }
        }
    }
    .elegible-status{
      .MuiChip-filled {
        border-radius: 5px;
        font-weight: 500;
        &.Not-Yet{
          background-color: #ffe5c8 !important;
          color: #ec8000;
        }
        &.Eligible{
          background-color: #cdfde0 !important;
          color: #33b469 !important;
        }
        &.Not-Eligible{
          background-color: #ffdada !important;
          color: red !important;
        }
      }
    }
    .gut-questionnaire-status{
      .MuiChip-filled {
        border-radius: 5px;
        font-weight: 500;
        &.Not-Attended{
          background-color: #ffe5c8 !important;
          color: #ec8000;
        }
        &.Complete{
          background-color: #cdfde0 !important;
          color: #33b469 !important;
        }
        &.Incomplete{
          background-color: #ffdada !important;
          color: red !important;
        }
      }
    }

    .user-present{
      .MuiChip-filled {
        border-radius: 5px;
        font-weight: 500;
        &.True{
          background-color: #cdfde0 !important;
          color: #33b469 !important;
        }
        &.False{
          background-color: #ffdada !important;
          color: red !important;
        }
      }
    }

    .kit-status{
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;

      .MuiChip-root{
        min-width: 185px;
      }
    }
    

  }

  .premium-filter-container {
    max-width: 100%; 
    background-color: #fff;   
    border-radius: 10px;
    
    .MuiToggleButtonGroup-root{
      button{
        font-weight: 600;
        font-size: 12px;
      }
    }
  
    .content {    
      overflow: hidden;
      max-height: 0;
      padding: 0 10px;
      margin-bottom: 0px;
      transition: max-height 0.5s ease-out, padding 0.4s ease-out, margin 0.4s ease-out;
      box-shadow: 1px 1px 10px #c7c7c7;
      border-radius: 10px;

      .filter-section {
          display: flex;
          // justify-content: space-between;
          margin-bottom: 10px;
          

          .filter-item{
            // border:1px solid grey;
            border-radius: 10px;
            margin: 0px 10px;
            .title{
              padding: 5px 0;
              // border:1px solid #0000001f;
            }
            .MuiToggleButtonGroup-root{
              margin: 0;
              padding:0;
            }
          }
        }
    }
  
    .content.expanded {    
      max-height: 250px;
      padding: 10px;
      margin-bottom: 10px;
      transition: max-height 0.5s ease-in, padding 0.4s ease-in, margin 0.4s ease-in;
    }
  
  
  }
}

.offcanvas{
  &.premium-user-view{
    width: 50% !important;
    margin: auto;
  }
  .footer{
    justify-content: flex-end;
  }
}

.premium-user-view{
  .premium-modal{
    padding: 0.5rem;
    box-shadow: 1px 1px 10px #c7c7c7;
    border: none;
  }
  .info{
    display: flex;
    justify-content: flex-start;
    .user-avatar{
      height: 45px;
      width: 45px;
      img{
        height: auto;
      }
    }
    .icon-button{
      background-color: #f3f3f9;
      height: 45px;
      width: 45px;
      margin-right: 10px;
    }
    .content-section{
      p{
        margin-bottom: 2px;
        &.title{
          font-size: 13px;
          color: #878a99;
        }
        &.value{
          font-size: 14px;
          color: #28084b;
          font-weight: 500;
        }
      }
      .ring-section{ 
        display: flex;
        justify-content: space-between;
        align-items: center;
      .ring-color {
        height: 15px;
        width: 15px;
        display: block;
        border-radius: 10%;
      }
    }
    }
  }

  .card-header{
    background-color: #fff;
  }

  .diagnostics-kit-view{
    margin: 10px 0;
    .card {
      border: none;
      box-shadow: 1px 1px 10px #c7c7c7;
        .card-header {
          display: flex;
          justify-content: space-between;

          &.kit-header{
            background-color: #ffe5c8;
            color: #ec8000;
          }          
        }
    
        .card-body {
          padding: 10px 10px;
    
          .order-info {
            p {
              margin-bottom: 5px;
              font-size: 14px;
              a{
                text-decoration: none;
                margin-left: 5px;
              }
            }
          }
        }
      }
  }
  .diagnostics-order-view{
    margin: 10px 0;

    .chip-info{
      width: 100%;
      text-align: center;
      padding: 10px;
      height: 35px;
    }

    .card {
      border: none;
      box-shadow: 1px 1px 10px #c7c7c7;
        .card-header {
          display: flex;
          justify-content: space-between;

          &.order-header{
            background-color: #ffe5c8;
            color: #ec8000;
          }          
        }
    
        .card-body {
          padding: 10px 10px;
    
          .order-info {
            p {
              margin-bottom: 5px;
              font-size: 14px;
            }
          }
        }
      }
  }

  .device-kit-view{
    margin: 10px 0;
    .card {
      border: none;
      box-shadow: 1px 1px 10px #c7c7c7;
        .card-header {
          display: flex;
          justify-content: space-between;

          &.kit-header{
            background-color: #ffe5c8;
            color: #ec8000;
          }          
        }
    
        .card-body {
          padding: 10px 10px;
    
          .order-info {
            p {
              margin-bottom: 5px;
              font-size: 14px;
              a{
                text-decoration: none;
                margin-left: 5px;
              }
            }
          }
        }
      }
  }

  .order-shipment{
    .card{
      border: none;
      box-shadow: 1px 1px 10px #c7c7c7;
    }
    .card-header{
      background-color: #ffe5c8;
      color: #ec8000;
    }
  }
}

@media only screen and (max-width: 767px) {  
  .offcanvas{
    &.premium-user-view{
      width: 90% !important;
    }
  }
  .premium-user-section{
    .premium-filter-container {
      .content {
        .filter-section {
          .filter-item {
            margin: 0px 0px;
          }
        }
  
        &.expanded {
          max-height: 250px;
          overflow-y: scroll;
        }
      }
    }
  }
  
}

.modal-backdrop.show{
  opacity: 0.1 !important;
}