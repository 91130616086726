* {
  font-family: 'Smitch Sans' !important;
}

.dt-section {
  &.meal-dt {
    .user-section {
      display: flex;
      flex-direction: column;

      .name {
        font-size: 14px;
        font-weight: 600;
      }

      .id {
        font-size: 12px;
      }
    }

    .meal-type {
      text-transform: capitalize;

      svg {
        margin-right: 5px;
      }

      .breakfast {
        color: #179a00;
      }

      .lunch {
        color: #ff9b00;
      }

      .dinner {
        color: #1500ff;
      }
      
      .snack {
        color: #9a1c00;
      }
    }

    .MuiAvatar-circular {
      margin-left: -25px;

      img {
        border-radius: 50%;
      }
    }

    .verified-svg {
      font-size: 25px;      
      border-radius: 50%;
      img{
        width: 40px;
      }      

      &.verified {
        background: #001e3c;
        color: #009632;
      }

      &.pending {
        background: brown;
        color: brown;
      }
    }
  }
}
.all-select-toogle{
  color: #9c27b0 !important;
  background-color: rgba(156, 39, 176, 0.08) !important;
}

.offcanvas {
  border-radius: 10px;
  &.food-intake-view{
    width: 95vw !important;
  }
  .offcanvas-header{
    border-radius:10px;
    background: linear-gradient(45deg, #2f7aaf, #2f7aaf);
    .btn-close {
      color: #fff;
    }
  }
  
  &.offcanvas-top {
    height: 95vh;
    width: 95vw;
    background-color: #efefef;
    margin: 2.5vh 2.5vw;
  }

  .maximize-icon{
    color: white;
    filter: drop-shadow(0px 0px 2px black);
    position: absolute;
    right: 10px;
    top: 10px;
    font-size: 20px;
    cursor: pointer;
  }
}

.slider-container{
  padding: 0 15px;
  border-radius: 20px;
  .slick-prev{
    left:-20px !important;
  }
  .slick-next{
    right:-20px !important;
  }
  .slick-prev:before,
    .slick-next:before {
      color: #009688;
    }

  .slick-list{
    border-radius: 10px;
  }
}

.slick-slide {
  .meal-log-image{
    height: 400px;
    width: 400px;
    padding: 5px;
    position:relative;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    // height: auto;
    // object-fit: cover;
    border-radius: 10px;
  }
}

.slick-dots {
  bottom: -65px !important;
  position: unset !important;
  li {
    width: 17% !important;
    min-height: 65px;
    // height: 100% !important;
    &.slick-active{
      img{
        border: 2px solid #009688;
        border-radius: 10px;
        box-shadow: 1px 0px 10px #009688;
    }
      
    }
    img {
      // width: 50px;
      // height: 50px;
      width: 100%;
      height: 100%;
      border-radius: 10px ;
      border:2px solid transparent;
    }

  }
}

.Intake-info{
  background: -webkit-linear-gradient(left,#9febc2 0,#f7f7f7 100%);
  padding: 10px;
  display: flex;
  justify-content: space-between;
}

.Intake-info-header{
  // background: -webkit-linear-gradient(left,#9febc2 0,#f7f7f7 100%);
  padding-right: 1rem;
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-size: 16px;

  .user-name{
    // color: #000000;
  }
  .meal-type{
    text-transform: capitalize;
  }
}

.food-intake-search-section{
  margin-bottom: 15px;
}

.food-intake-add {
  margin-top: 0px !important;
  position: relative;

  .user-looged-foods {
    padding-bottom: 20px;

    .accordion-item:last-of-type {
      border: 2px solid #ffb0b0;
    }

    .accordion-body {
      background: -webkit-linear-gradient(left, #f7f7f7 0, #f7f7f7 100%);
    }

    .accordion-item:first-of-type .accordion-button,
    .accordion-button:not(.collapsed) {
      background: -webkit-linear-gradient(left, #f9c5c5 0, #f7f7f7 100%);
      font-weight: 800;
      font-size: 14px;
    }

    .food-item {
      &.non-editable {
        background: -webkit-linear-gradient(left, #f9c5c5 0, #f7f7f7 100%);
        border: 2px solid #ffb0b0;
      }
    }
  }

  .looged-foods-nutrients {
    padding-bottom: 10px;

    .accordion-item:last-of-type {
      border: 2px solid #b0baff;
    }

    .accordion-body {
      background: -webkit-linear-gradient(left, #c5d3f9 0, #f7f7f7 100%);
    }

    .accordion-item:first-of-type .accordion-button,
    .accordion-button:not(.collapsed) {
      background: -webkit-linear-gradient(left, #c5d3f9 0, #f7f7f7 100%);
      font-weight: 800;
      font-size: 14px;
    }

    .food-item {
      &.non-editable {
        background: -webkit-linear-gradient(left, #c5d3f9 0, #f7f7f7 100%);
        border: 2px solid #ffb0b0;
      }
    }
  }

  .food-item {
    background: -webkit-linear-gradient(left, #c5f9dd 0, #f7f7f7 100%);
    margin-bottom: 5px !important;
    border: 2px solid #fffefe;

    &.deleted-meal {
      background: lightgray !important;
      border-color: #c1c1c1;
    }

    h4 {
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }

    p {
      font-size: 13px;
      font-weight: 600;

    }
  }

  .serving-section {
    padding-left: 0px;

    div {
      &:first-child {
        margin-right: 5px;
      }
    }

    p {
      font-size: 12px;
    }
  }

  .dropdown-menu {
    max-height: 250px;
    overflow-y: scroll;
    z-index: 9999;
  }

  .meal-image-fullview {
    width: 100%;
    height: 100%;

    img {
      width: 100%;
      border-radius: 10px;
      object-fit: cover;
    }

  }

  .intake-footer {
    // position: absolute;
    bottom: 0;
    width: calc(100% - 2vw);

    .inner-div {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .verify-switch {
        background: #d7d7d7;
        border: 2px solid #737373;
        border-radius: 10px;
        min-width: 175px;
      }

      .save-btn {
        font-size: 25px;
      }
    }

  }

  .MuiSwitch-thumb::before {
    background-size: contain;
  }

  .MuiSwitch-switchBase.Mui-checked .MuiSwitch-thumb:before {
    background-size: contain;
  }
}

.home-card {
  :first-child{
    padding-left: 0px;
  }
  :nth-child(2){
    padding: 0;
  }
  :last-child{
    padding-right: 0px;
  }
  .card{
    padding: 1rem;
    padding-bottom: 0.5rem;
    box-shadow: 0px 0px 8px #cfcfcf;
    &.primary{
      border-top: 10px solid #0084f4;
    }
    &.success{
      border-top: 10px solid #00c48c;
    }
    &.danger{
      border-top: 10px solid #ff647c;
    }

    .heading {
      color: #6c6c6c;
      font-size: 1.18em;
      margin-bottom: 0px;
    }
  
    .count {
      font-weight: 600;
      font-size: 2.5em;
      line-height: 50px;
      color: #323c43;
      margin-bottom: 0px;
    }
  }
    
}

.micro-nutrient-card{
  margin-bottom: 10px;
  .item{
    background-color: white;
    padding: 10px;
    border-radius: 10px;
    box-shadow: 1px 1px 10px #939292;
    h4{
      font-size: 20px;
      margin-bottom: 0px;
      font-weight: 600;
      span{
        font-size: 16px;
      }
    }
  }
}

.filter-modal{
  &.offcanvas.offcanvas-end{
    width: 40%;
  }
  .offcanvas-header{
    padding: 10px 10px;
    &.footer{
      justify-content: space-between;
      background: transparent;
    }
  }
  .header-chip{
    width: 100%;
    border-radius: 10px;
  }
  .filter-checkbox{
    display: flex;
    flex-direction: row;
  }
  .date-range-picker-container {
    .rdrDefinedRangesWrapper{
      display: none;
    }
    .rdrDateRangePickerWrapper,
    .rdrCalendarWrapper  {
      width: 100%;
    }
    .rdrMonthsVertical{
      .rdrMonth{
        width: auto;
      }
    }
  }
}

.date-range-picker{
  .MuiTextField-root{
    width: 100%;
    min-width:auto !important;
  }
  input{
    padding: 10px 10px;
  }
}
.verified-tick{
  font-size: 18px;
  .verified{
    color: green;
  }
  .not-verified{
    color: #ff5500;
  }

}
.action-button-group{
  text-align: end;
}

@media only screen and (max-width: 767px) {
  .action-button-responsive{
    span{
      display: none;
    }
  }
  .action-button-group{
    button{
      span{
        display: none;
      }
    }
  }
  .filter-modal {
    &.offcanvas {
        width: 90% !important;      
    }
  }
  .slider-container{
    padding: 10px 15px;
    .slick-slide {
      .meal-log-image{
        height: auto;
      }
    }
  }

  .Intake-info-header{
    &>div{
      text-align: center;
    }
    .meal-type{
      text-transform: capitalize;
    }
    .log-time{
      display: flex;
    }
  }
}

// .grid-container {
//   gap: 10px;
//   display: flex;
//   justify-items: center;
//   align-items: center;
//   justify-content: center;
//   width: 100%;

//   .grid-item {
//     position: relative;
//     width: 20%;
//     height: 200px;
//     // width: 100px;
//     // height: 100px;
//   }

//   .grid-image {
//     width: 100%;
//     height: 100%;
//     border-radius: 10px;
//     object-fit: cover;
//   }

//   .meal-log-image{
//     img{
//       border: 2px solid transparent;
//     }
//     &.viewed{
//       img{
//         border: 2px solid #ff7676;
//         box-shadow: 1px 1px 12px #656565;
//       }
      
//     }
//   }
// }