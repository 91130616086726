


.kit-order-section,
.pickup-order-section {
  .rdt_TableHead {
    .rdt_TableCol {
      background-color: #4b8bba;
      color: #fffefe;
      font-weight: 500 !important;
      padding: 10px 0px;
      justify-content: center;
      font-size: 14px;

      &:nth-child(3),
      &:nth-child(2){
        padding: 10px;
        text-align: start;
        justify-content: left;
      }
      
      &:nth-child(1),
      &:nth-child(4),
      &:nth-child(5),
      &:nth-child(6){
        padding: 10px;
        text-align: center;
        justify-content: center;
      }
    }
  }

  .rdt_TableRow {
    .rdt_TableCell {
      
      &:nth-child(3),
      &:nth-child(2){
        padding: 10px;
        text-align: start;
        justify-content: left;
      }
      
      &:nth-child(1),
      &:nth-child(4),
      &:nth-child(5),
      &:nth-child(6){
        padding: 10px;
        text-align: center;
        justify-content: center;
      }
    }

    .chip-border{
      border-radius: 5px;
    }
    .shipment-status{
      text-transform: capitalize;
      min-width: 100px;
    }
    .product-name{
      min-width: 110px;
    }

    .rdt_TableRow .rdt_TableCell{
      font-size: 14px;
    }
    
    .rdt_TableRow .rdt_TableCell{
      text-align: center;
      justify-content: center;
    }
    
    .rdt_TableRow .rdt_TableCell>div {
      text-align: center;
      justify-self: center;
    }
    
  }

  .dt-order-table,
  .dt-kit-table{
    border-radius: 10px !important;
    border: 1px solid lightgray;
  }

  .dt-table .rdt_Pagination{
    border-top: none;
    background-color: transparent;
  }

}






/* Head */


/* Body */



/*  */

.detail-cards{
  padding: 5px 10px;
  text-align: left;
  border-radius: 10px !important;
  border: 2px solid transparent;
  // cursor: pointer;

  &.active{
    background-color: #ebebeb;
    border: 2px solid #9c27b0;
  }

  .block{
    display:flex;
    justify-content: space-around;
    align-items: center;

    img{
      width: 100%;
      max-width: 50px;
    }

    .content{
      text-align: left;
    }

    .heading {
      font-size: 14px;
      margin-bottom: 10px;
      color: #7b7676;
    }
    .value {
      font-size: 35px;
      margin-bottom: 5px;
      color: #000;
    }
  }

  
}

.id .ASC,
.id .DESC,
.name .ASC,
.name .DESC,
.mobile .ASC,
.mobile .DESC,
.email .ASC,
.email .DESC,
.order_status .ASC,
.order_status .DESC,
.status .ASC,
.status .DESC,
.order_id .ASC,
.order_id .DESC {
  display: none;
}

.id.ASC .id .ASC,
.id.DESC .id .DESC,
.name.ASC .name .ASC,
.name.DESC .name .DESC,
.mobile.ASC .mobile .ASC,
.mobile.DESC .mobile .DESC,
.email.ASC .email .ASC,
.email.DESC .email .DESC,
.order_status.ASC .order_status .ASC,
.order_status.DESC .order_status .DESC,
.status.ASC .status .ASC,
.status.DESC .status .DESC,
.order_id.ASC .order_id .ASC,
.order_id.DESC .order_id .DESC{
  display: block !important;
}

.top-action-button {
  text-transform: none;
  border-radius: 5px;
  padding: 5px 10px;
}

@media only screen and (max-width: 767px) {
  .pl-0{
    padding-left:0px
  }
  .pr-0{
    padding-right:0px
  }
  .top-action-button {
    padding: 8px 10px !important;
    min-width: unset !important;
    width: 100%;
    // margin-left:10px;
    span {
      display: none;
    }
  }
  
  .action-button-responsive{
    padding: 10px !important;
    span{
      display: none;
    }
  }

  .detail-cards{
    .block{
      img{
        max-width: 40px;
      }
      .heading {
        font-size: 12px;
      }
      .value {
        font-size: 30px;
      }
    }
  }
}