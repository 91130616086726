@import url('./assets/css/fonts.css');

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header,
.App-footer {
  border: 2px solid #f3f5f8;
  background: linear-gradient(45deg, #5fd795, transparent);
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: calc(10px + 2vmin);
  color: white;
  border-radius: 10px;
  padding: 0 10px;
}

.App-footer {
  /* position: fixed; */
  bottom: 0;
  width: 100%;
  margin-top: 20px;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.hide {
  display: none !important;
}

.dt-user-list,
.offcanvas-body,
.ScrollDesign {
  scrollbar-width: thin;
  /* scrollbar-height: thin; */
  scrollbar-color: #2f7aaf #ffffff;
}

.dt-user-list::-webkit-scrollbar,
.offcanvas-body::-webkit-scrollbar,
.ScrollDesign::-webkit-scrollbar {
  width: 10px;
  height:5px;
}

.dt-user-list::-webkit-scrollbar-track,
.offcanvas-body::-webkit-scrollbar-track,
.ScrollDesign::-webkit-scrollbar-track {
  background: #ffffff;
}

.dt-user-list::-webkit-scrollbar-thumb,
.offcanvas-body::-webkit-scrollbar-thumb,
.ScrollDesign::-webkit-scrollbar-thumb {
  background-color: #2f7aaf;
  border-radius: 10px;
  border: 2px solid #ffffff;
}

.dt-user-list::-webkit-scrollbar-thumb:hover,
.offcanvas-body::-webkit-scrollbar-thumb:hover,
.ScrollDesign::-webkit-scrollbar-thumb:hover {
  background-color: #6b5ec9;
}
/*  */

.component-heading{
  font-size: 20px;
  text-align: left;
  color: #025087;
}